<template>
  <div class="mainwrap banneredit" v-loading="loading">
    <div class="title">餐廳資訊詳情</div>
    <div class="formwrap">
      <el-form
          :model="counterForm"
          ref="bannerForm"
          label-width="150px"
          label-position="left"
      >
        <el-form-item label="餐廳名稱">
          <el-input
              v-model="counterForm.name"
              disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="電話">
          <el-input
              v-model="counterForm.phone"
              disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input
              v-model="counterForm.address"
              disabled="disabled"
          ></el-input>
        </el-form-item>

        <el-form-item label="餐廳LOGO">
          <el-image :src="counterForm.logo" style="width: 180px">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </el-form-item>

        <el-form-item label="詳情圖片">
          <el-carousel height="180px" :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,index) in counterForm.image" :key="item+index">
              <el-image :src="item">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </el-carousel-item>
          </el-carousel>
<!--          <div class="imgDetail">-->
<!--            <el-image v-for="item in counterForm.image" style="width: 180px" :src="item" :key="item">-->
<!--              <div slot="placeholder" class="image-slot">-->
<!--                加载中<span class="dot">...</span>-->
<!--              </div>-->
<!--            </el-image>-->
<!--          </div>-->
        </el-form-item>
        <el-form-item label="營業時間">
          <div>{{counterForm.opening && counterForm.opening.slice(0,-1)}}</div>
        </el-form-item>

        <el-form-item label="菜單">
          <el-button v-for="(item,index) in counterForm.menu" :key="index" @click="openMenu(item)">{{item.note}}</el-button>
        </el-form-item>
        <el-form-item label="地圖坐標">
          <div>{{counterForm.landmark}}</div>
        </el-form-item>
        <el-form-item label="分店資訊">
          <div v-html="counterForm.content"></div>
        </el-form-item>

        <el-form-item label="最新活動">
          <div class="box">
            <div class="title">
              <div>活動id</div>
              <div>活動名稱</div>
              <div>活動時間</div>
              <div>是否隱藏</div>
            </div>
            <draggable v-model="counterForm.news">
              <div v-for="element in counterForm.news" :key="element.id" class="centent">
                <div>{{element.id}}</div>
                <div>{{element.title}}</div>
                <div>{{element.publishAt}}</div>
                <div>
                    <el-select :disabled="permissionType !== 2" v-model="element.isHide" placeholder="请选择">
                      <el-option label="请选择" value=""></el-option>
                      <el-option label="是" :value="true"></el-option>
                      <el-option label="否" :value="false"></el-option>
                  </el-select>
                </div>
              </div>
            </draggable>
          </div>
        </el-form-item>

<!--
        <el-form-item label="最新活動">
            <el-table
                :data="counterForm.news"
                stripe
                border
                style="width: 100%">
              <el-table-column
                  prop="id"
                  label="活動id">
              </el-table-column>
              <el-table-column
                  prop="title"
                  label="活動名稱">
              </el-table-column>
              <el-table-column
                  prop="publishAt"
                  label="活動時間">
              </el-table-column>
              <el-table-column
                  label="排序">
                <template slot-scope="scope">
                  <el-input @input="onlyNumber($event,scope.row)" v-model="scope.row.sort" placeholder="請輸入排序"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                  label="是否隱藏">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.isHide" placeholder="请选择">
                      <el-option label="请选择" value=""></el-option>
                      <el-option label="是" :value="true"></el-option>
                      <el-option label="否" :value="false"></el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
        </el-form-item> -->


        <el-form-item label="社群連結">
          <div class="linkbox" v-for="(item,index) in communityList" :key="index">
            <el-select :disabled="permissionType !== 2" v-model="communityList[index].type" placeholder="請選擇" :class='{"formitem-error": isSubmit && !communityList[index].type }'>
              <el-option
                v-for="item in linkList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <span>連結文字：</span>
            <el-input :disabled="permissionType !== 2" v-model="communityList[index].title" :maxlength="15" placeholder="請輸入文字" :class='{"formitem-error": isSubmit && !communityList[index].title }'></el-input>
            <span>連結URL：</span>
            <el-input
                :disabled="permissionType !== 2"
              v-model="communityList[index].url"
              :class='{"formitem-error": isSubmit && !communityList[index].isURL}'
              placeholder="https://或http://開頭"
              @change="checkURL(index)"
            >
            </el-input>
            <i class="el-icon-delete-solid" v-if="permissionType === 2" @click="deleteCommunity(index)"></i>
          </div>
          <el-button v-if="permissionType === 2" size="small" type="success" @click="addLink">新增</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button v-if="permissionType === 2" type="primary" :disabled="isSubmit" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import draggable from 'vuedraggable'
import { getStore} from '@/utils/storage';

export default {
  components: {
    draggable
  },
  data() {
    return {
      loading: false,
      detailId: -1,
      counterForm: {},
      news: [],
      isSinglePage: false,
      currentPage: 1,
      linkSearch:"",
      linkList:[{value:1,label:"LINE"},{value:2,label:"FaceBook"},{value:3,label:"Instagram"}],
      communityList:[],
      isSubmit:false,
      permissionType: -1
    };
  },
  created() {
    this.permissionType = parseInt(getStore("permissionType"));
    let queryObj = this.$route.query || {};
    if ( queryObj.id) {
      this.detailId = queryObj.id
      this.getDetail()
    }
  },
  methods: {
    onlyNumber(e,ie){
      ie.sort = e.replace(/[^\d]/g,'')
    },
    // 取消
    cancel() {
      this.$router.push({path:"/cms/restaurantlist"});
    },
    getDetail() {
      this.loading = true;
      api.counterDetail(this.detailId).then(res=>{
        if(res.systemCode===200){
          this.counterForm = res.data
          this.counterForm.landmark = (this.counterForm.lat + "," + this.counterForm.lng);
          this.communityList=res.data.community.map(item=>{
            return {
              ...item,
              isURL:true
            }
          });
        }else{
          // this.$message.error(res.message);
        }
        this.loading = false;
      })
    },
    addLink(){
      this.communityList.push({
        type: "",
        title: "",
        url: "",
        isURL:true
      })
    },
    onSubmit(){
      this.isSubmit=true;
      let community=[];
      this.news = []
      let reg= new RegExp(/^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/);
      for(let i=0;i<this.communityList.length;i++){
        if(this.communityList[i].type && this.communityList[i].title && reg.test(this.communityList[i].url)){
          community.push({
            type: this.communityList[i].type,
            title: this.communityList[i].title,
            url: this.communityList[i].url,
          })
        }else if(!reg.test(this.communityList[i].url)){
          console.log(this.communityList);
          this.communityList[i].isURL=false;
          this.$message.error('請檢查輸入');
          this.isSubmit=false;
          return;
        }else{
          this.$message.error('請檢查輸入');
          this.isSubmit=false;
          return;
        }
      }
      console.log(this.counterForm);
      if(this.counterForm.news && this.counterForm.news.length!=0) {
        this.counterForm.news.forEach((item,index) => {
          this.news.push({
            'id': item.id,
            'sort': index,
            'isHide': item.isHide
          })
        });
      }
      console.log(this.news);
      api.counterEdit({
        id:this.detailId,
        community:community,
        news:this.news
      }).then(res=>{
        if(res.systemCode===200){
          this.$message.success('提交成功');
          this.$router.push({ path: "/cms/restaurantlist" });
        }
        this.isSubmit=false;
      })
    },
    checkURL(idx){
      let reg= new RegExp(/^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/);
      if(reg.test(this.communityList[idx].url)){
        this.communityList[idx].isURL=true;
      }else{
        this.communityList[idx].isURL=false;
      }
    },
    deleteCommunity(idx){
      this.communityList.splice(idx, 1);
    },
    openMenu(menu){
      window.open(menu.url,"_blank")
    }
  },
};
</script>

<style lang="scss" scoped>
.banneredit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 560px;
    .inlineblock {
      .el-input {
        width: 80%;
        margin-right: 10px;
      }
    }
  }
  .imgDetail{
    display: flex;
  }
  .linkbox{
    width: 800px;
    margin-bottom: 10px;
    span{
      padding-left: 20px;
    }
    .el-select{
      width: 120px;
    }
    .el-input{
      width: 200px;
      vertical-align: middle;
    }
    .ml10{
      margin-left: 10px;
    }
    .el-icon-delete-solid{
      display: inline-block;
      cursor: pointer;
      color: #f56c6c;
      font-size: 20px;
      padding-left: 10px;
      height: 32px;
      line-height: 32px;
      vertical-align: middle;
    }
  }
  .box {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #EBEEF5;
    .title {
      width: 100%;
      display: flex;
      height: 35px;
      padding: 0;
      background-color: #f6f6f6;
      > div {
        width: 25%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #666;
        border: 1px solid #EBEEF5;
      }
    }
    .centent {
      &:nth-child(2n) {
      background-color: #f6f6f6;
      }
      &:hover {
      background-color: #f5f7fa;
      }
      width: 100%;
      display: flex;
      > div {
        width: 25%;
        font-size: 14px;
        color: #333;
        border: 1px solid #EBEEF5;
        display: flex;
        align-items: center;
        padding: 10px;
      }
    }
  }
}
</style>
