<template>
  <div class="mainwrap banneredit" v-loading="loading">
    <div class="title">banner編輯</div>
    <div class="formwrap">
      <el-form
        :model="bannerForm"
        :rules="rules"
        ref="bannerForm"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="名稱" prop="name">
          <el-input
            v-model="bannerForm.name"
            placeholder="請輸入名稱"
          ></el-input>
        </el-form-item>

        <el-form-item label="圖片" prop="imageUrl">
          <el-upload
            class="img-uploader"
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleChange"
            :on-success="handleSuccess"
          >
            <img
              v-if="bannerForm.imageUrl"
              :src="bannerForm.imageViewUrl"
              class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
              @click.stop.prevent
              v-if="bannerForm.imageUrl"
              class="deletewrap"
            >
              <i class="el-icon-delete" @click="handleRemove"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，圖片比例4:3
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="開始時間" prop="startTime">
          <el-date-picker
            v-model="bannerForm.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="選擇開始時間"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="結束時間" prop="endTime">
          <el-date-picker
            v-model="bannerForm.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="選擇結束時間"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
            <el-input
                v-model="bannerForm.sort"
                @input="onlyNumber($event)"
                placeholder="請輸入"
            ></el-input>
        </el-form-item>

        <el-form-item label="鏈接" prop="linkType">
          <el-radio-group v-model="bannerForm.linkType" @change="changeLinkType">
            <el-radio :label=1>餐廳</el-radio>
            <el-radio :label=2>最新消息</el-radio>
            <el-radio :label=5>URL</el-radio>
            <el-radio :label=0>無鏈接</el-radio>
          </el-radio-group>

          <div v-if="bannerForm.linkType !== '' && bannerForm.linkType ===1 || bannerForm.linkType===2 " class="inlineblock">
            <el-input disabled v-model="bannerForm.linkName"></el-input>
            <el-button type="primary" @click="openLinkVisible">選擇</el-button>
          </div>
          <div v-if="bannerForm.linkType !== '' && bannerForm.linkType ===5" class="inlineblock">
            <el-input v-model="bannerForm.url"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="是否發佈" prop="isActive">
          <el-select v-model="bannerForm.isActive" placeholder="请选择是否發佈">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>

    <el-dialog title="選擇鏈接" :visible.sync="linkVisible" width="40%">
      <div class="linkSearchBox">
        <el-input v-model="linkSearch"></el-input>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </div>
      <el-table :data="linkVisibleData">
        <el-table-column property="value" label="名稱"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="selectLink(scope.row)" type="primary" plain>
              選擇
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="tableDataTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api"
import {tableDataPagination,getImageInfo} from "@/utils/common.js"
export default {
  data() {
    var validateLink = (rule, value, callback) => {
      let urlReg = new RegExp(/^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/)
      if (value === "") {
        callback(new Error("請選擇跳轉鏈接"));
      } else {
        if (
          this.bannerForm.linkType !== "" && (this.bannerForm.linkType ===1 || this.bannerForm.linkType ===2) &&
          this.bannerForm.linkName === ""
        ) {
          callback(new Error("請選擇跳轉鏈接"));
        } else if (
          this.bannerForm.linkType !== "" && this.bannerForm.linkType ===5 &&
          !this.bannerForm.url
        ) {
          callback(new Error("請輸入鏈接"));
        } else if (
          this.bannerForm.url && !urlReg.test(this.bannerForm.url)
        ) {
          callback(new Error("URL格式錯誤，https://或http://開頭 "));
        } 
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (new Date(value) <= new Date()) {
        callback(new Error('請輸入大於當前日期的時間'));
      } else {
        callback();
      }
    };
    return {
      loading:false,
      bannerForm: {
        id:"",
        name: "",
        imageUrl: "",
        startTime: "",
        endTime: "",
        sort: "",
        linkType: "",
        linkId:"",
        isActive: "",
        linkName:"",
        url:"",
        imageViewUrl:""
      },
      rules: {
        name: [{ required: true, message: "請輸入名稱", trigger: "blur" }],
        imageUrl: [
          {
            required: true,
            message: "請上傳圖片",
            trigger: ["change", "blur"],
          },
        ],
        startTime: [
          { required: true, message: "請選擇開始日期", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "請選擇結束日期", trigger: "blur" },
          { validator: validatePass2, trigger: "blur"}
        ],
        sort: [{ required: false, message: "請輸入排序", trigger: "change" }],
        linkType: [
          { required: true, message: "請選擇跳轉鏈接", trigger: "blur" },
          { validator: validateLink, trigger: ["change","blur"] },
        ],
        isActive: [
          { required: true, message: "請選擇是否發佈", trigger: "change" },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now());
          //此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      pickerOptions2: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，包含今天。
          return time.getTime() <= Date.now();
          //此条为设置禁止用户选择今天之前的日期，不包含今天。
          // return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      statusOptions: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      allRestauranList: [],
      allNewsList: [],
      linkVisible: false,
      linkVisibleData: [],
      isSinglePage: false,
      currentPage: 1,
      pageSize:10,
      linkSearch:"",
      tableDataTotal:0
    };
  },
  async created() {
    await this.getBannerSelect(1)
    await this.getBannerSelect(2)
    let queryObj = this.$route.query || {};
    if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
      this.getBannerDetails(queryObj.id)
    }else{
      this.loading=false;
    }
  },
  methods: {
    onlyNumber(e){
      this.couponForm.sort = e.replace(/[^\d]/g,'')
    },    
    // 獲取選擇列表
    async getBannerSelect(type){
      this.loading=true;
      await api.getbannerSelectData({type}).then(response=>{
        if(type===1){
          this.allRestauranList=response.data;
        }else{
          this.allNewsList=response.data;
        }
      })
    },
    // banner詳情
    getBannerDetails(id){
      api.getBannerDetails(id).then(res=>{
        this.bannerForm= Object.assign({},this.bannerForm,res.data);
        this.bannerForm.imageViewUrl= res.data.imageUrl;
        // this.bannerForm.imageViewUrl=process.env.VUE_APP_BASE_API +'/'+ res.data.imageUrl;
        this.bannerForm.linkName=this.filterLinkName(this.bannerForm.linkId);
        this.loading=false;
      })
    },
    // 獲取linkName
    filterLinkName(linkId){
      let nameItem=[];
      if(this.bannerForm.linkType===1){
        nameItem=this.allRestauranList.filter(item=>linkId===item.key);
      }else{
        nameItem=this.allNewsList.filter(item=>linkId===item.key);
      }
      return nameItem.length>=1? nameItem[0].value:"";
    },
    // 上傳圖片
    async handleChange(file, fileList) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then(res=>{
        if(res.systemCode===200){
          this.bannerForm.imageUrl = res.data;
          this.bannerForm.imageViewUrl = res.data;
          // this.bannerForm.imageViewUrl = process.env.VUE_APP_BASE_API +'/' + res.data;
          this.$message.success('圖片上傳成功');
        }
      })
      // this.bannerForm.imageUrl = URL.createObjectURL(file.raw);
      this.$refs.bannerForm.validateField("imageUrl");
    },
    handleSuccess(res, file) {
      console.log(file);
    },
    handleRemove() {
      this.bannerForm.imageUrl = "";
      this.bannerForm.imageViewUrl = "";
      this.$refs.bannerForm.validateField("imageUrl");
    },
    // 限制格式和大小
    beforeUpload(file) {
      const acceptArr = ["image/jpeg", "image/png"];
      const isJPG = acceptArr.indexOf(file.type) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 2;
      // let whIsTrue= false;
      // 获取宽高
      // let {imgHeight,imgWidth}=await getImageInfo(file);
      // whIsTrue= imgHeight/imgWidth===0.75 ? true:false; //(3:4=>0.75)
      // if (!whIsTrue) {
      //   this.$message.error("上傳圖片比例錯誤!");
      // }
      if (!isJPG) {
        this.$message.error("上傳圖片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上傳圖片大小不能超過 2MB!");
      }
      return isJPG && isLt2M;
    },
    // selectLink
    openLinkVisible() {
      this.currentPage=1;
      this.linkSearch="";
      if (this.bannerForm.linkType === 1) {
        this.getPageRes(this.allRestauranList);
      } else {
        this.getPageRes(this.allNewsList);
      }
      this.linkVisible = true;
    },
    selectLink(item) {
      this.bannerForm.linkId=item.key;
      this.bannerForm.linkName=item.value;
      this.linkVisible=false;
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage=val;
      if (this.bannerForm.linkType === 1) {
        this.getPageRes(this.allRestauranList);
      } else {
        this.getPageRes(this.allNewsList);
      }
    },
    // 查詢
    onSearch() {
      this.currentPage=1;
      if (this.bannerForm.linkType === 1) {
        this.getPageRes(this.allRestauranList);
      } else {
        this.getPageRes(this.allNewsList);
      }
    },
    // 處理表格數據
    getPageRes(allData){
      let res=tableDataPagination(allData,this.currentPage,this.pageSize,this.linkSearch);
      this.linkVisibleData=res.dataList;
      this.tableDataTotal=res.dataTotal;
    },
    // 提交
    onSubmit() {
      this.$refs["bannerForm"].validate(valid => {
        if (valid) {
          let params={
            name: this.bannerForm.name,
            imageUrl: this.bannerForm.imageUrl,
            startTime: this.bannerForm.startTime,
            endTime: this.bannerForm.endTime,
            sort: this.bannerForm.sort,
            linkType: this.bannerForm.linkType,
            linkId: this.bannerForm.linkId,
            isActive: this.bannerForm.isActive,
            url: this.bannerForm.url,
          }
          if(this.bannerForm.id){
            params.id=this.bannerForm.id
          }
          for (let key in params){
              if(params[key]===""){
                  delete params[key];
              }
          }
          api.bannerEdit(params).then(res=>{
            if(res.systemCode===200){
              this.$message.success('提交成功');
              this.$router.push({ path: "/cms/bannerlist" });
            }else{
              // this.$message.error(res.message);
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 類型選擇
    changeLinkType(type){
      // console.log(type);
      this.bannerForm.linkName="";
      this.bannerForm.linkId="";
      this.bannerForm.url="";
    },
    // 取消
    cancel() {
      this.$router.push({ path: "/cms/bannerlist" });
    },
  },
};
</script>

<style lang="scss" scoped>
.banneredit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 500px;
    .inlineblock {
      .el-input {
        width: 80%;
        margin-right: 10px;
      }
    }
  }
  .linkSearchBox{
    width: 500px;
    margin-bottom: 10px;
    .el-input {
        width: 250px;
        margin-right: 10px;
      }
  }
}
</style>
